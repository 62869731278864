import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

if (!dev && !env.PUBLIC_IS_TESTING) {
  Sentry.init({
    dsn: 'https://8742423435a61118bc34ec27464cd0ba@o4507744596393984.ingest.de.sentry.io/4507744598884432',
    tracesSampleRate: 1.0,
    // For instance, initialize Session Replay:
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    release: env.PUBLIC_COMMIT_HASH || 'local development',
  });
}

export const handleError = dev ? () => null : Sentry.handleErrorWithSentry();
