import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/admin/dashboard": [~5],
		"/advertising": [6],
		"/app": [~7],
		"/app/claim/[invitationId=integer]": [~27],
		"/app/claim/[invitationId=integer]/[claimToken]": [~28],
		"/app/new": [29],
		"/app/reject/[invitationId=integer]/[claimToken]": [~30],
		"/app/settings": [~31],
		"/app/[productId=integer]": [~8,[2]],
		"/app/[productId=integer]/coverpage": [9,[2]],
		"/app/[productId=integer]/history": [~10,[2]],
		"/app/[productId=integer]/parta": [11,[2]],
		"/app/[productId=integer]/partb": [12,[2]],
		"/app/[productId=integer]/partc": [13,[2]],
		"/app/[productId=integer]/partd": [14,[2]],
		"/app/[productId=integer]/parte": [15,[2]],
		"/app/[productId=integer]/partf": [16,[2]],
		"/app/[productId=integer]/partg": [17,[2]],
		"/app/[productId=integer]/parth": [18,[2]],
		"/app/[productId=integer]/parti": [19,[2]],
		"/app/[productId=integer]/partj": [20,[2]],
		"/app/[productId=integer]/partk": [21,[2]],
		"/app/[productId=integer]/partl": [22,[2]],
		"/app/[productId=integer]/partm": [23,[2]],
		"/app/[productId=integer]/partn": [24,[2]],
		"/app/[productId=integer]/parto": [25,[2]],
		"/app/[productId=integer]/share": [26,[2]],
		"/compliance": [32],
		"/faq": [33],
		"/legals": [34],
		"/login": [~35],
		"/register": [~36],
		"/reset": [37],
		"/support": [38]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';